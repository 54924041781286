<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for>TIPO</label>
          <SelectType
            v-model="type_id"
            :disabled="disabled || selTypDis"
          ></SelectType>
          <app-small-form-errors
            :errors="errors.type_id"
          ></app-small-form-errors>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for>ALMACEN</label>
          <SelectStore
            ref="elSelectStore"
            v-model="store_id"
            :disabled="disabled"
          ></SelectStore>
          <app-small-form-errors
            :errors="errors.store_id"
          ></app-small-form-errors>
        </div>
      </div>
    </div>

    <!-- <div class="form-group">
      <label for>MONEDA</label>
      <app-select-currency :verbose="true" v-model="reg.money_code" :disabled="disabled"></app-select-currency>
      <app-small-form-errors :errors="errors.money_code"></app-small-form-errors>
    </div> -->
    <div class="form-group" v-show="!id">
      <app-checkbox v-model="includeBill">
        Incluir comprobante <small>(factura/boleta)</small>
      </app-checkbox>
    </div>

    <FormBill
      ref="elFormBill"
      v-show="includeBill"
      :disabled="disabled"
    ></FormBill>

    <div
      class="form-group"
      v-show="!(disabled && (!observation || observation.length == 0))"
    >
      <label for="">Observaciones</label>
      <app-textarea :disabled="disabled" v-model="observation"></app-textarea>
    </div>

    <app-button-loading
      v-show="!disabled"
      :disabled="errors"
      :loading="sending"
      @click="save()"
    >
      Guardar
    </app-button-loading>

    <div class="form-group" v-if="output_id">
      <router-link
        :to="{
          name: 'logistic-output-show',
          params: { output_id: output_id }
        }"
        >transferencia de otra area</router-link
      >
    </div>
  </div>
</template>

<script>
import validate from "validate.js";
import SelectType from "./SelectType";
import SelectStore from "../stores/Select.vue";
import { LogisticService } from "../service";
import FormBill from "./FormBill.vue";

const formRules = {
  type_id: { presence: { message: "Requerido" } },
  store_id: { presence: { message: "Requerido" } }
  //money_code: { presence: { message: "Requerido" } }
};

export default {
  components: {
    SelectType,
    SelectStore,
    FormBill
  },
  props: {
    disabled: {
      default: false
    },
    selTypDis: {
      default: true
    }
    // redirectAfterSave: {
    //   default: true
    // }
  },
  data: () => ({
    id: null,
    store_id: null,
    observation: "",
    type_id: null,
    output_id: null,

    readonly: false,

    includeBill: false,
    // bill_id  : null,
    sending: false
  }),
  computed: {
    errors() {
      let err = validate(this.$data, formRules, { fullMessages: false });
      return err ? err : false;
    }
  },
  methods: {
    setValue(input) {
      this.id = input.id;
      //this.store_id = input.store_id;
      this.$refs.elSelectStore.setValue(input.store_id);

      this.observation = input.observation;
      this.type_id = input.type_id;
      this.output_id = input.output_id;

      // this.readonly = input.readonly;

      if (input.bill_id && input.bill) {
        this.includeBill = true;
        this.$refs.elFormBill.setValue(input.bill);
      }
    },
    async save() {
      console.log("errores aqui", this.errors);
      if (!this.errors) {
        try {
          this.sending = true;
          var input = await LogisticService.saveInput({
            id: this.id,
            store_id: this.store_id,
            observation: this.observation,
            type_id: this.type_id,
            bill: this.includeBill ? this.$refs.elFormBill.getData() : null
          });
          this.$emit("submitted", input);
        } catch (e) {
          //

          console.error(e);
        }
        this.sending = false;
      }
    },
    setStore(storeId) {
      this.$refs.elSelectStore.setValueFromId(storeId);
    },
    reset({ store_id }) {
      this.$refs.elFormBill.reset();
      this.id = null;
      this.store_id = null;
      this.observation = "";
      //this.type_id = null;
      this.output_id = null;

      this.type_id = 1;

      this.includeBill = false;

      if (store_id) {
        this.$refs.elSelectStore.setValueFromId(store_id);
      }
    }
  }
};
</script>

<style></style>
