<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for="">Nombre</label>
      <input
        ref="elInputName"
        type="text"
        class="form-control"
        v-model="name"
      />
      <app-invalid-feedback :errors="errors.name"></app-invalid-feedback>
    </div>
    <app-button-submit :loading="sending">Guardar</app-button-submit>
  </form>
</template>
<script>
import validator from "src/lt/utils/validator.js";
import { LogisticService } from "../service.js";

const formRules = {
  name: {
    presence: { allowEmpty: false }
  }
};

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    name: "",
    sending: false
  }),

  computed: {
    errors() {
      return validator(this.$data, formRules);
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    focus() {
      this.$refs.elInputName.focus();
    },
    async save() {
      this.sending = true;
      try {
        let res = await LogisticService.saveSupplier({
          name: this.name
        });
        this.$emit("submitted", res);
      } catch (e) {
        this.sending = false;
      }
    },
    reset() {
      this.name = "";
      this.sending = false;
    }
  }
};
</script>

<style scoped></style>
