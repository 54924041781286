<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<template>
  <div>
    <div class="row" v-show="!disabled">
      <div class="col mb-3">
        <button class="btn btn-success btn-sm" @click="createSupplierHandler()">
          Registrar Proveedor
        </button>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-lg-4">
        <label for>PROVEEDOR</label>
        <SelectSupplier
          ref="elSelectSupplier"
          v-model="supplier_id"
          :disabled="disabled"
        ></SelectSupplier>
        <app-invalid-feedback
          :errors="errors.supplier_id"
        ></app-invalid-feedback>
      </div>
      <div class="form-group col-lg-4">
        <label for>TIPO DE COMPROBANTE</label>
        <SelectProofPayment
          v-model="invoice_type"
          :disabled="disabled"
        ></SelectProofPayment>
        <app-invalid-feedback
          :errors="errors.invoice_type"
        ></app-invalid-feedback>
      </div>

      <div class="form-group col-lg-4">
        <label for>NUMERO DE TICKET</label>
        <input
          type="text"
          v-model="invoice_code"
          class="form-control"
          :disabled="disabled"
        />
        <app-invalid-feedback
          :errors="errors.invoice_code"
        ></app-invalid-feedback>
      </div>
      <div class="form-group col-lg-4">
        <label for>FECHA DE EMISION</label>
        <app-datepicker
          :disabled="disabled"
          v-model="date_issue"
        ></app-datepicker>
        <app-invalid-feedback
          :errors="errors.date_issue"
        ></app-invalid-feedback>
      </div>
      <div class="form-group col-lg-4">
        <label for>GUIA</label>
        <input
          type="text"
          v-model="adds.guide"
          class="form-control"
          :disabled="disabled"
        />
      </div>
    </div>

    <app-modal-basic ref="elModalCreateSupplier">
      <SupplierFastForm
        ref="elSupplierFastForm"
        @submitted="
          $refs.elSelectSupplier.setValue($event.id);
          $refs.elModalCreateSupplier.hide();
        "
      ></SupplierFastForm>
    </app-modal-basic>
  </div>
</template>
<script>
import validator from "src/lt/utils/validator.js";
import SelectSupplier from "../suppliers/Select.vue";
import SelectProofPayment from "../SelectProofPayment";
import SupplierFastForm from "../suppliers/FastForm.vue";

const formRules = {
  supplier_id: { presence: { allowEmpty: false } },
  invoice_code: { presence: { allowEmpty: false } },
  invoice_type: { presence: { allowEmpty: false } },
  money_code: { presence: { allowEmpty: false } },
  date_issue: { presence: { allowEmpty: false } }
};

export default {
  components: {
    SelectSupplier,
    SelectProofPayment,
    SupplierFastForm
  },

  // directives
  // filters

  props: {
    disabled: {
      default: false
    }
  },

  data: () => ({
    supplier_id: null,
    invoice_code: "",
    invoice_type: null,
    money_code: 1,
    date_issue: "",
    adds: {}
  }),

  computed: {
    errors() {
      return validator(this.$data, formRules);
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    setValue(bill) {
      //this.supplier_id = bill.supplier_id;
      this.$refs.elSelectSupplier.setValue(bill.supplier_id);
      this.invoice_code = bill.invoice_code;
      this.invoice_type = bill.invoice_type;
      this.money_code = bill.money_code;
      this.date_issue = bill.date_issue;
      this.adds = bill.adds || {};
    },
    createSupplierHandler() {
      this.$refs.elModalCreateSupplier.show();
      this.$refs.elSupplierFastForm.reset();
      this.$refs.elSupplierFastForm.focus();
    },
    reset() {
      //this.supplier_id = null;
      this.$refs.elSelectSupplier.reset();
      this.invoice_code = "";
      this.invoice_type = null;
      this.money_code = 1;
      this.date_issue = "";
      this.adds = {};
    },
    getData() {
      return JSON.parse(JSON.stringify(this.$data));
    }
  }
};
</script>

<style scoped></style>
